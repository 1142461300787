<template>
  <slot />
</template>

<script setup lang="ts">
import { TYPE } from 'vue-toastification';
import type { CartActionType } from '~/stores/cartStore/model';
import { useCartStore } from '~/stores/cartStore/cartStore';
import { useHTMLDictionary } from '~/composables/useHTMLDictionary';
import { isDefined } from '~/utils/guards/isDefined';

const { $shopStore } = useNuxtApp();

const htmlDictionary = useHTMLDictionary([
  'pdp.addToCart.success.message',
  'pdp.removeFromCart.success.message',
  'pdp.addToRequestCart.success.message',
  'pdp.removeFromRequestCart.success.message',
  'pdp.addToRequestCart.success.message.redirect'
]);

const QUERY_PARAM_AUTOREDIRECT = 'rcshortcut';
const REDIRECT_STORES = [
  'apac_en',
  'apac_jp',
  'apac_ko',
  'apac_zhtw',
  'china_cn'
];

const cartStore = useCartStore();
const toasts = useShowToast();
const route = useRoute();

const cartActionLabelMap = computed<Partial<Record<CartActionType, string>>>(
  () => ({
    addItems:
      htmlDictionary.value['pdp.addToCart.success.message'] ??
      'pdp.addToCart.success.message',
    removeItem:
      htmlDictionary.value['pdp.removeFromCart.success.message'] ??
      'pdp.removeFromCart.success.message'
  })
);
const requestCartActionLabelMap = computed<
  Partial<Record<CartActionType, string>>
>(() => ({
  addItems:
    htmlDictionary.value['pdp.addToRequestCart.success.message'] ??
    'pdp.addToRequestCart.success.message',
  removeItem:
    htmlDictionary.value['pdp.removeFromRequestCart.success.message'] ??
    'pdp.removeFromRequestCart.success.message'
}));

watch(
  () => cartStore.lastAction,
  () => {
    if (!cartStore.lastAction) {
      return;
    }

    const error = cartStore.lastAction.error;
    if (error) {
      const message = Array.isArray(error) ? error.join('\n') : error;

      toasts.showToast(message, {
        type: TYPE.ERROR,
        timeout: 2000
      });

      return;
    }

    const message =
      cartStore.activeCartType === 'requestCart' &&
      !isDefined(route.query[QUERY_PARAM_AUTOREDIRECT]) &&
      REDIRECT_STORES.indexOf($shopStore.value) > -1
        ? htmlDictionary.value[
            'pdp.addToRequestCart.success.message.redirect'
          ] ?? 'pdp.addToRequestCart.success.message.redirect'
        : cartStore.activeCartType === 'requestCart'
        ? requestCartActionLabelMap.value[cartStore.lastAction.type]
        : cartActionLabelMap.value[cartStore.lastAction.type];
    if (message) {
      if (
        cartStore.activeCartType === 'requestCart' &&
        cartStore.lastAction.type === 'addItems' &&
        !isDefined(route.query[QUERY_PARAM_AUTOREDIRECT]) &&
        REDIRECT_STORES.indexOf($shopStore.value) > -1
      ) {
        toasts.showToast(message, {
          type: TYPE.SUCCESS,
          timeout: 1000,
          onClose: () => {
            if (cartStore.currentCartLink) {
              window.location.href = cartStore.currentCartLink;
            }
          }
        });
      } else {
        toasts.showToast(message, {
          type: TYPE.SUCCESS,
          timeout: 2000
        });
      }
    }
  }
);
</script>
